import React from "react"
import Layout from "../components/layout/layout.jsx"
import style from "../components/layout/layout.module.sass"
import SEO from "../components/seo.jsx"
import MusicPlayerLarge from "../components/music-player-large/music-player-lg.jsx"

const MusicPage = () => (
  <Layout>
    <SEO title="Music" />
    <div className={style.pageContainerWithMargin}>
      <MusicPlayerLarge />
    </div>
  </Layout>
)

export default MusicPage
