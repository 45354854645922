import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import style from "./music-player-lg.module.sass"
import Prev from '../../images/media-player/prev-lg.svg';
import Play from '../../images/media-player/play-lg.svg';
import Pause from '../../images/media-player/pause-lg.svg';
import Next from '../../images/media-player/next-lg.svg';
import trackEvent from '../../utilities/trackEvent';
import { Video } from '../../utilities/video'
import ReactPlayer from 'react-player'
import { FaApple, FaSpotify } from 'react-icons/fa';
import  classNames from 'classnames';

import Listen from '../../videos/listen.mp4';

export default () => {
  const fullQuery = useStaticQuery(query)
  const videoPoster = fullQuery.videoPoster.childImageSharp.fluid.src
  const contentFullSong = fullQuery.allContentfulSong.edges
  const songs = []

  contentFullSong.map(({ node }) => {
    return songs.push({
      title: node.title,
      file: node.song.file.url,
      buyLink: node.buyLink,
      streamLink: node.streamLink,
      downloadLink: node.downloadLink,
    })
  })

  const linkSpotifyProfile = "https://open.spotify.com/artist/7u6zPPV9AUZnERJOslec7L?si=XGOEEP-LQSy4vZS3_LTsuQ";
  const linkItunesProfile = "https://music.apple.com/no/artist/sa-zeiner/1465215446";

  const [currentSongNumber, setCurrentSongNumber] = useState();
  const [currentSongTitle, setCurrentSongTitle] = useState();
  const [downloadBuyLink, setDownloadBuyLink] = useState(linkItunesProfile);
  const [downloadBuyText, setDownloadBuyText] = useState('Apple Music');
  const [streamLink, setStreamLink] = useState(linkSpotifyProfile);
  const [playPauseIcon, setPlayPauseIcon] = useState(<Play />);
  let audio;

  if (typeof window !== 'undefined') {
    [audio] = useState(new Audio());
  }

  useEffect(
    () => {
      if (currentSongNumber !== undefined) {
        audio.src = songs[currentSongNumber].file
        setCurrentSongTitle(songs[currentSongNumber].title)
        setStreamLink(songs[currentSongNumber].streamLink)
        if (songs[currentSongNumber].buyLink === null) {
          setDownloadBuyLink(songs[currentSongNumber].downloadLink)
          setDownloadBuyText('download')
        } else {
          setDownloadBuyLink(songs[currentSongNumber].buyLink)
          setDownloadBuyText('Apple Music')
        }
        audio.play()
        trackEvent("click", "Song: " + songs[currentSongNumber].title, "media", "play")
        setPlayPauseIcon(<Pause />)
      }
      return () => {
        audio.pause()
      }
    },
    [currentSongNumber]
  );

  const playSong = () => {
    if (currentSongNumber === undefined) {
      setCurrentSongNumber(0)
      audio.src = songs[0].file
      audio.play()
    } else {
      if (audio.paused) {
        audio.play()
        setPlayPauseIcon(<Pause />)
        setCurrentSongTitle(songs[currentSongNumber].title)
      } else {
        audio.pause();
        setPlayPauseIcon(<Play />)
      }
    }
  }

  const nextSong = () => {
    if (currentSongNumber < contentFullSong.length - 1) {
      setCurrentSongNumber(currentSongNumber + 1)
    } else {
      setCurrentSongNumber(0)
    }
  }

  const prevSong = () => {
    if (currentSongNumber > 0) {
      setCurrentSongNumber(currentSongNumber - 1)
    } else {
      setCurrentSongNumber(contentFullSong.length - 1)
    }
  }

  const appleMusicClass = classNames(style.faIcon, style.appleIcon)
  const spotifyClass = classNames(style.faIcon)

  return (
    <div className={style.playerContainer}>
      <h2 className={style.songTitle}>{currentSongTitle}</h2>
      <div className={style.videoloop}>
        <ReactPlayer config={{
          file: { 
            attributes: { 
              poster: videoPoster
            } 
          } 
        }} width="100%" className={style.videoPlayer} url={[Listen]} loop muted playsinline playing />
        {/* <Video width={"100%"} height={"100%"} className={style.videoPlayer} src={[Listen]}/> */}
      </div>
      <div className={style.mediaControls}>
        <div onClick={prevSong} className={style.prev}><Prev /></div>
        <div onClick={playSong} className={style.play}>{playPauseIcon}</div>
        <div onClick={nextSong} className={style.next}><Next /></div>
      </div>
      <div className={style.buttons}>
        <div className={style.btn} onClick={() => trackEvent("conversion", "Song: " + currentSongTitle, "media", downloadBuyText)}>
          <a href={downloadBuyLink} className={style.btnLink} target="_blank" rel="noopener noreferrer">
            <h2><FaApple className={appleMusicClass}/>MUSIC</h2>
          </a>
        </div>
        <div className={style.btn} onClick={() => trackEvent("conversion", "Song: " + currentSongTitle, "media", "stream")}>
          <a href={streamLink} target="_blank" className={style.btnLink} rel="noopener noreferrer">
            <h2 className={style.alignCenter}><FaSpotify className={spotifyClass} />Spotify</h2>
          </a>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
query {
  allContentfulSong {
    edges {
      node {
        buyLink
        downloadLink
        song {
          file {
            url
          }
        }
        streamLink
        title
      }
    }
  }
  videoPoster: file(relativePath: { eq: "listen.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 600) {
        src
        }
      }
    }
  }
`
