import React from 'react'

export const Video = (props) => (
        <div className={props.className}>
            <video 
                muted 
                autoPlay
                loop
                playsInline 
                preload="auto"
                width={props.width} 
                height={props.height}
                src={props.src} 
                poster={props.poster}
            />
        </div>
    )